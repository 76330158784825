@import "../../../styles/custom";

.article-page {
  padding-top: 0;
  padding-bottom: 64px;
  overflow: visible;

  .time-display {
    margin-top: 64px;
    margin-bottom: 0;
  }
  &__time-frame-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin-top: 16px;
    margin-bottom: 16px;
    padding-inline-start: 0px !important;
    a {
      color: $business-500;
      text-decoration: underline;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
    }
    li {
      padding-inline-start: 4px !important;
      margin-bottom: 0px !important;
      margin-top: 0px !important;
    }
  }
  &__link-wrapper {
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }
  &__phone-wrapper {
    @include media-breakpoint-down(lg) {
      width: calc(100% - 40px);
      margin-left: 40px;
    }
  }
  @at-root {
    body[lang="ar"] {
      .article-page__phone-wrapper {
        direction: ltr;
        @include media-breakpoint-down(md) {
          margin-right: 40px;
          margin-left: 0;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding-top: 80px;
    padding-bottom: 168px;
  }

  hr {
    border-color: $gray-200;
    opacity: 1;

    @include media-breakpoint-down(lg) {
      margin: 24px 0;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $gray-900;
    font-weight: 600;
  }

  h2 {
    margin-top: 80px;
    margin-bottom: 16px;
    font-size: 32px;
    @include media-breakpoint-down(lg) {
      font-size: 24px;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  h3,
  h4,
  h5,
  h6 {
    margin-top: 16px;
    margin-bottom: 24px;
  }

  h2,
  h3 {
    width: max-content;
    max-width: 100%;

    &:hover {
      .copy-icon-wrapper {
        &::after {
          visibility: visible;
        }
      }
    }

    &:focus {
      &:not(:focus-visible) {
        box-shadow: none;
      }

      &:focus-visible {
        @include focus();
      }
    }

    .copied-icon-wrapper {
      &::after {
        @include media-breakpoint-up(md) {
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' width='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill='%23173255' fill-rule='evenodd' d='M12.5 3.5c1.1046 0 2 .89543 2 2v1.7c0 .55229-.4477 1-1 1s-1-.44771-1-1V5.5h-7v7h1.70154c.55228 0 1 .4477 1 1s-.44772 1-1 1H5.5c-1.10457 0-2-.8954-2-2v-7c0-1.10457.89543-2 2-2h7Z' clip-rule='evenodd'/%3E%3Cpath fill='%23173255' fill-rule='evenodd' d='M9.5 11.5c0-1.1046.8954-2 2-2h7c1.1046 0 2 .8954 2 2v7c0 1.1046-.8954 2-2 2h-7c-1.1046 0-2-.8954-2-2v-7Zm2 0v7h7v-7h-7Z' clip-rule='evenodd'/%3E%3C/svg%3E%0A") !important;
        }
      }
    }

    .copy-icon-wrapper {
      position: relative;
      inset-inline-start: 8px;

      &::after {
        display: inline-block;
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' width='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill='%230060FF' fill-rule='evenodd' d='M12.5 3.5c1.1046 0 2 .89543 2 2v1.7c0 .55229-.4477 1-1 1s-1-.44771-1-1V5.5h-7v7h1.70154c.55228 0 1 .4477 1 1s-.44772 1-1 1H5.5c-1.10457 0-2-.8954-2-2v-7c0-1.10457.89543-2 2-2h7Z' clip-rule='evenodd'/%3E%3Cpath fill='%230060FF' fill-rule='evenodd' d='M9.5 11.5c0-1.1046.8954-2 2-2h7c1.1046 0 2 .8954 2 2v7c0 1.1046-.8954 2-2 2h-7c-1.1046 0-2-.8954-2-2v-7Zm2 0v7h7v-7h-7Z' clip-rule='evenodd'/%3E%3C/svg%3E%0A");
        width: 24px;
        height: 24px;
        position: relative;
        visibility: visible;
        top: 4px;

        cursor: pointer;

        @include media-breakpoint-up(lg) {
          visibility: hidden;
        }
      }

      &:hover {
        .copy-icon-tooltip {
          visibility: visible;
        }

        &::after {
          @include media-breakpoint-up(md) {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' width='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill='%23204D88' fill-rule='evenodd' d='M12.5 3.5c1.1046 0 2 .89543 2 2v1.7c0 .55229-.4477 1-1 1s-1-.44771-1-1V5.5h-7v7h1.70154c.55228 0 1 .4477 1 1s-.44772 1-1 1H5.5c-1.10457 0-2-.8954-2-2v-7c0-1.10457.89543-2 2-2h7Z' clip-rule='evenodd'/%3E%3Cpath fill='%23204D88' fill-rule='evenodd' d='M9.5 11.5c0-1.1046.8954-2 2-2h7c1.1046 0 2 .8954 2 2v7c0 1.1046-.8954 2-2 2h-7c-1.1046 0-2-.8954-2-2v-7Zm2 0v7h7v-7h-7Z' clip-rule='evenodd'/%3E%3C/svg%3E%0A");
          }
        }
      }

      &:focus {
        &:not(:focus-visible) {
          box-shadow: none;
        }

        &::after {
          @include media-breakpoint-up(md) {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' width='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill='%23173255' fill-rule='evenodd' d='M12.5 3.5c1.1046 0 2 .89543 2 2v1.7c0 .55229-.4477 1-1 1s-1-.44771-1-1V5.5h-7v7h1.70154c.55228 0 1 .4477 1 1s-.44772 1-1 1H5.5c-1.10457 0-2-.8954-2-2v-7c0-1.10457.89543-2 2-2h7Z' clip-rule='evenodd'/%3E%3Cpath fill='%23173255' fill-rule='evenodd' d='M9.5 11.5c0-1.1046.8954-2 2-2h7c1.1046 0 2 .8954 2 2v7c0 1.1046-.8954 2-2 2h-7c-1.1046 0-2-.8954-2-2v-7Zm2 0v7h7v-7h-7Z' clip-rule='evenodd'/%3E%3C/svg%3E%0A");
          }
        }

        &:focus-visible {
          @include focus();
        }
      }

      .copy-icon-tooltip {
        position: absolute;
        padding: 20px;
        font-size: 14px;
        line-height: 150%;
        background-color: $white;
        border: 1px solid $gray-100;
        border-radius: 8px;
        box-shadow: $shadow-light;
        white-space: nowrap;
        bottom: calc(100% + 5px);
        transform: translateX(-50%);
        inset-inline-start: 50%;
        visibility: hidden;
        pointer-events: none;
        display: block;

        @include media-breakpoint-down(lg) {
          display: none;
        }

        @at-root {
          body[lang="ar"] {
            .article-page .copy-icon-tooltip {
              transform: translateX(50%);
            }
          }
        }
      }
    }
  }

  &__publish-date {
    margin: 40px 0 0 0;
    strong {
      margin-inline-end: 4px;
    }
  }

  ol {
    padding-inline-start: 16px;

    li {
      padding-inline-start: 7px;
      margin-bottom: 8px;
    }
  }
  @at-root {
    body[lang="ar"] {
      .article-page{
        ul {
          padding-inline-start: 16px;
        }
      }
      
    }
  }

  ul {
    padding-inline-start: 16px;
    list-style: disc;
    li {
      &::marker {
        color: $business-600 !important;
      }
    }
  }

  img {
    max-width: 100%;
    object-fit: contain;
  }

  &__content-wrapper {
    padding-top: 40px;

    @include media-breakpoint-up(lg) {
      padding-top: 0;
    }
  }
  &__date-wrapper {
    display: flex;
  }

  &__confidentiality {
    margin-top: 64px;
    margin-bottom: 64px;
    div[data-component="Accordion"] {
      div[data-component="Box"] {
        button {
          div[data-component="Box"] {
            div[data-component="Box"] {
              margin-inline-start: 0px !important;
            }
          }
        }
      }
      div[role="region"] {
        div[data-component="Box"] {
          padding-top: 0;
        }
      }
    }
    &__content {
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;
    }
    &__accordion-wrapper {
      border-radius: 8px;
      border: 1px solid $gray-200;
      padding: 0px 32px 0px 32px;
      margin-bottom: 8px;
    }

    &__title {
      font-size: 32px;
      font-weight: 600;
      line-height: 150%;
      margin-bottom: 24px;
      @include media-breakpoint-down(lg) {
        font-size: 24px;
      }
    }
  }
  &__communication-channels-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;
    margin-bottom: 64px;
  }
  &__communication-channels-box {
    width: 100%;
    display: flex;
    gap: 16px;
    align-items: flex-start;
    justify-content: space-between;
    padding: 24px;
    border-radius: 8px;
    border: 1px solid $gray-200;
    &__last-line {
      margin-top: 8px;
      display: flex;
      align-items: center;
      @include media-breakpoint-down(md) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    &__availability {
      margin-inline-end: 8px;
      span {
        color: $gray-900;
        font-weight: 600;
      }
    }
    &__responseTime {
      display: flex;
      align-items: center;
      span {
        color: $gray-900;
        font-weight: 600;
        margin-inline-start: 4px;
      }
      &::before {
        margin-inline-end: 8px;
        content: "";
        width: 1px;
        height: 16px;
        background-color: $gray-200;
      }
      @include media-breakpoint-down(md) {
        &::before {
          content: none;
        }
      }
    }
    p {
      margin: 0;
    }
    &__title {
      font-weight: 600;
      line-height: 150%;
    }
    &__phone {
      font-weight: 500;
      line-height: 150%;
      white-space: nowrap;
      text-decoration: underline;
    }
    &__link {
      font-weight: 500;
      line-height: 150%;
      display: flex;
      color: $business-500;
      background: transparent;
      padding: 11px 24px 13px 24px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      border: 1px solid $gray-300;
      white-space: nowrap;
      &:hover {
        color: $business-600;
      }

      &:active {
        color: $business-800;
      }
      &:focus {
        &:focus-visible {
          background-color: $warning-500;
          color: $gray-900;
          outline: none;
        }
      }

      @include media-breakpoint-down(lg) {
        width: 100%;
      }
    }
    &__icon-box {
      display: flex;
      gap: 16px;
      width: 100%;
    }
    @include media-breakpoint-down(lg) {
      flex-direction: column;
    }
  }
  &__icon-children-wrapper {
    font-weight: 500;
    line-height: 150%;
    display: flex;
    gap: 8px;
  }
  &__note-wrapper {
    display: flex;
    background: $business-50;
    border: 1px solid $info-100;
    border-radius: 8px;
    border-left: 0;
    &__bar {
      background: $info-500;
      width: 2px;
      align-self: stretch;
      border-radius: 8px 0 0 8px;
    }
    &__textbox {
      padding: 16px;
      display: flex;
      gap: 12px;

      p {
        font-weight: 600;
        margin-bottom: 0;
      }
      ul {
        padding-inline-start: 16px;
        li {
          &::marker {
            color: $gray-900 !important;
          }
        }
      }
    }
  }
}

.toast.toast-copy-message {
  box-shadow: none;
  border: 1px solid $success-100;
  width: calc(100% - 32px);
  position: fixed;
  bottom: 32px;

  @include media-breakpoint-up(md) {
    width: calc(map-get($container-max-widths, "md") * 0.66);
  }

  @include media-breakpoint-up(lg) {
    width: calc(map-get($container-max-widths, "lg") * 0.66);
  }

  @include media-breakpoint-up(xl) {
    width: calc(map-get($container-max-widths, "xl") * 0.66);
  }

  @include media-breakpoint-up(xxl) {
    width: calc(map-get($container-max-widths, "xxl") * 0.66);
  }

  @include media-breakpoint-up(xxxl) {
    width: calc(map-get($container-max-widths, "xxxl") * 0.66);
  }

  .toast-body {
    display: flex;
    align-items: center;
    color: $gray-900;
    background-color: $success-50;
    padding: 16px;
    font-size: 16px;
    line-height: 150%;

    .checkmark-success {
      background-color: $success-500;
      color: $white;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-inline-end: 12px;

      .icon {
        height: 12px;
        width: 12px;
        stroke-width: 2px;
        position: relative;
        inset-block-end: 4px;
        inset-inline-start: 3px;
        stroke: $white;
      }
    }

    .toast-close-button {
      background: transparent;
      border: none;
      padding: 0;
    }

    p {
      flex-grow: 1;
      margin: 0;
    }
  }
}
