@import "../../../../../../styles/custom";
.download {
  border: 1px solid $gray-100;
  background-color: $gray-50;
  width: 100%;
  height: 144px;
  border-radius: 8px;
  position: relative;
  padding: 24px;
  box-sizing: border-box;

  &.download--rtl {
    direction: rtl;
  }

  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 24px;
  }
  &__file-name {
    color: $gray-900;
    margin-left: 8px;
    font-size: 16px;
    letter-spacing: 0;
    &.download__file-name--rtl {
      margin-right: 8px;
      margin-left: 0px;
      direction: rtl;
    }
  }
  &__icon {
    width: 19px;
    color: $business-500;
    height: 19px;
    margin-right: 8px;
  }
  &__icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }
  &__button-container {
    background: $gray-0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $gray-0;
    border-radius: 8px;
    border: 1px solid $gray-300;
    width: 243px;
    height: 48px;
    border-radius: 4px;
    margin-top: 24px;
  }
  &__button-label {
    color: $business-500;
    font-size: 16px;
    letter-spacing: 0;
  }
}
