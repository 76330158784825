@import "../../../../../styles/custom";
.labor-education-article__body {
  padding-inline-start: 0px;
  margin-top: 56px;
  @include media-breakpoint-down(lg) {
    margin-top: 40px;
    margin-bottom: 40px;
    padding-right: 16px;
    padding-left: 16px;
  }

  &.table-of-contents-articles {
    @include media-breakpoint-down(lg) {
      padding-top: 24px;
      padding-bottom: 24px;
    }
  }
}
.table-of-contents-sticky {
  @include media-breakpoint-down(lg) {
    position: static;
    top: 50px;
    z-index: 100;
  }
}

.full-width {
  width: 100%;
}

.sticky-top {
  z-index: 1;
  position: sticky;
  top: 0;
  margin-bottom: 20px;
  @include media-breakpoint-down(lg) {
    margin-bottom: 0px;
  }

  &.sticky-top {
    top: 110px;
  }
  &.sticky-top--rtl {
    top: 110px !important;
    position: sticky;
    top: 0;
    margin-bottom: 20px;
    direction: rtl;
  }
  @include media-breakpoint-down(lg) {
    margin-left: 0 !important;
    width: 100%;
  }
}
.labor-education-article__sidebar {
  position: sticky;
  top: 0;
  height: 100vh;
  overflow: auto;
}

.full-width-banner {
  width: 100vw;
  position: relative;
  margin-left: -34.2vw;

  @include media-breakpoint-down(md) {
    width: auto;
    margin-left: 0;
  }
}
.labor-education-article .feedback .container {
  max-width: 100vw;
  padding: 40px 156px !important;
}
.labor-education-article__container {
  margin-bottom: 96px;
  @include media-breakpoint-down(md) {
    margin-bottom: 40px;
  }
}
.labor-education-article__popular-questions {
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 24px;
  margin-top: 80px;
  @include media-breakpoint-down(md) {
    font-size: 24px;
    margin-top: 36px;
  }
}

.labor-education-article__media-center-title {
  font-size: 32px;
  font-weight: 600;
  line-height: 130%;
  margin-top: 120px;
  margin-bottom: 40px;
  @include media-breakpoint-down(md) {
    font-size: 24px;
    margin-top: 120px;
  }
}
.labor-education-article__question-container {
  .accordion {
    background-color: $gray-0;
  }
  .container {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .article__content {
    margin-bottom: 24px;
    @include media-breakpoint-down(lg) {
      font-size: 16px;
    }
  }
  .article__title {
    margin-bottom: 0px;
  }
  .learn-more {
    margin-bottom: 24px;
    margin-top: 0 !important;
    @include media-breakpoint-down(lg) {
      margin-bottom: 16px;
    }
  }
  .text-wrapper p {
    margin-top: 0px;
  }
}
.labor-education-article .container {
  @include media-breakpoint-down(lg) {
    max-width: 100vw !important;
  }
}
.labor-education-article .icon-cards-group .container {
  @include media-breakpoint-down(lg) {
    max-width: calc(100% - 32px) !important;
  }
}
.labor-education-article-hero .container {
  @include media-breakpoint-down(lg) {
    max-width: calc(100% - 32px) !important;
  }
}
.time-display {
  @include media-breakpoint-down(lg) {
    margin-top: 32px !important;
    margin-bottom: 48px !important;
  }
}
