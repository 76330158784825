@import "../../styles/custom";

.mobile-profile {
  width: 100%;
  padding: 16px;
  background-color: $white;
  border-radius: 12px;

  &__title {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: $gray-900;
    margin-bottom: 8px;
  }
  &__header {
    background-color: $business-100;
    border-radius: 8px; 
    padding: 12px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__avatar {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    background-color: $gray-100;

    .qiwa-avatar {
      width: 100%;
      height: 100%;
    }
  }

  &__info {
    flex-grow: 1;
  }

  &__name {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }

  &__arrow {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray-600;
    border-radius: 50%;
    border: 2px solid $business-300;

    svg {
      color: $business-500;
      width: 16px;
      height: 16px;
    }
  }

  [dir="rtl"] & {
    .mobile-profile__arrow {
      transform: rotate(180deg);
    }
  }
}
  
  
