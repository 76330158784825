@import "../../../../../../../styles/custom";
.hero-section {
  padding-top: 64px;
  padding-bottom: 144px;
  color: $gray-50;
  background-color: $business-800;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  background: $business-800 url("../ServiceArticleDesktop.png") no-repeat right
    37px bottom 0;
  background-size: 645px 355px;
  @include media-breakpoint-down(md) {
    background: $business-800 url("../ServiceArticleMobile.png") no-repeat
      center bottom 0;
    background-size: contain;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 32px;
  }

  &--special {
    background: $business-800;
  }
  &__headline {
    align-self: stretch;
    color: $gray-100;
    font-size: 48px;
    font-weight: 700;
    word-wrap: break-word;
  }
  &__text {
    text-align: center;
    align-self: stretch;
    color: $gray-100;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    word-wrap: break-word;
  }

  &__group {
    background: $business-600;
    padding: 11px 24px 13px 24px;
    border-radius: 4px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: flex;
  }

  &__button {
    background: none;
    align-self: stretch;
    border: none;
  }
}
.hero-section--rtl {
  direction: rtl;
  background: $business-800 url("../ServiceArticleDesktop.png") no-repeat left
    37px bottom 0px;
  background-size: 645px 355px;
  @include media-breakpoint-down(md) {
    background: $business-800 url("../ServiceArticleMobile.png") no-repeat
      center bottom 0;
    background-size: contain;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 32px;
  }
}
