@import "../../../../../styles/custom";

.linking-occupations-page {
  padding-top: 32px;
  background-color: $gray-50;

  @include media-breakpoint-up(lg) {
    padding-top: 64px;
  }
  @at-root {
    body[lang="ar"] {
      .linking-occupations-page {
        ul {
          padding-inline-start: 0px;
        }
      }
    }
  }
}
