@import "../../styles/custom";

.table-of-contents__wrapper {
  @include media-breakpoint-down(lg) {
    width: 100vw;
    margin-inline-start: -16px;
    border-bottom: 1px solid $gray-200;
    // margin-bottom: 56px;
  }

  @include media-breakpoint-up(lg) {
    position: sticky;
    position: -webkit-sticky;
    top: 160px;
  }
}

.table-of-contents {
  background-color: $gray-50;
  padding: 32px 24px 0;

  @include media-breakpoint-up(lg) {
    background-color: $white;
    outline: 1px solid $gray-200;
    max-height: 640px;
    overflow-y: auto;
    width: 280px;
    padding: 32px 24px 8px;
  }

  h2 {
    margin-bottom: 0 !important;
    font-size: 20px !important;
    font-weight: 600;
    line-height: 140%;
  }

  h4 {
    font-size: 24px;
    font-weight: 600;
    line-height: 140%;
    margin-top: 0 !important;
    margin-bottom: 0 !important;

    @include media-breakpoint-up(lg) {
      margin-bottom: 24px;
    }
  }

  ol {
    padding: 0 !important;
    list-style: none;

    li {
      padding: 0 !important;
      margin-bottom: 0 !important;
    }
  }

  ul {
    padding: 0 !important;
    list-style: none !important;

    li {
      padding-inline-start: 0 !important;
      margin-top: 0 !important;
      margin-bottom: 16px !important;
    }
  }

  li.active-toc {
    .table-of-contents__second-level-link {
      color: $business-800;

      &::before {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24'%3E%3Cpath fill='currentColor' fill-rule='evenodd' d='M11.7929 5.89336c.3905-.39053 1.0237-.39053 1.4142 0l5.3995 5.39954c.3905.3905.3905 1.0237 0 1.4142l-5.3995 5.3995c-.3905.3905-1.0237.3905-1.4142 0-.3906-.3905-.3906-1.0237 0-1.4142L15.4853 13H6.10046c-.55228 0-1-.4477-1-1s.44772-1 1-1h9.38484l-3.6924-3.69243c-.3906-.39052-.3906-1.02369 0-1.41421Z' clip-rule='evenodd' style='fill: %230C3243;' /%3E%3C/svg%3E%0A");
      }
    }
  }

  &__first-level-link {
    color: $gray-900;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    margin-top: 24px !important;
    margin-bottom: 16px !important;
    display: block;

    &:hover {
      color: $business-500;
    }

    &:focus {
      &:not(:focus-visible) {
        box-shadow: none;
      }

      &:focus-visible {
        @include focus();
      }
    }
  }

  &__second-level-link {
    color: $business-500;
    font-size: 14px;
    font-weight: 500;
    display: flex;

    &:hover {
      color: $business-600;

      &::before {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24'%3E%3Cpath fill='currentColor' fill-rule='evenodd' d='M11.7929 5.89336c.3905-.39053 1.0237-.39053 1.4142 0l5.3995 5.39954c.3905.3905.3905 1.0237 0 1.4142l-5.3995 5.3995c-.3905.3905-1.0237.3905-1.4142 0-.3906-.3905-.3906-1.0237 0-1.4142L15.4853 13H6.10046c-.55228 0-1-.4477-1-1s.44772-1 1-1h9.38484l-3.6924-3.69243c-.3906-.39052-.3906-1.02369 0-1.41421Z' clip-rule='evenodd' style='fill: %23293034;' /%3E%3C/svg%3E%0A");
      }
    }

    &:focus {
      &:not(:focus-visible) {
        box-shadow: none;
      }

      &:focus-visible {
        @include focus();
      }
    }

    &::before {
      width: 20px;
      height: 20px;
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24'%3E%3Cpath fill='currentColor' fill-rule='evenodd' d='M11.7929 5.89336c.3905-.39053 1.0237-.39053 1.4142 0l5.3995 5.39954c.3905.3905.3905 1.0237 0 1.4142l-5.3995 5.3995c-.3905.3905-1.0237.3905-1.4142 0-.3906-.3905-.3906-1.0237 0-1.4142L15.4853 13H6.10046c-.55228 0-1-.4477-1-1s.44772-1 1-1h9.38484l-3.6924-3.69243c-.3906-.39052-.3906-1.02369 0-1.41421Z' clip-rule='evenodd' style='fill: %230060ff;'/%3E%3C/svg%3E%0A");
    }

    @at-root {
      body[lang="ar"] {
        .table-of-contents__second-level-link {
          &:hover {
            &::before {
              content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24'%3E%3Cpath fill='currentColor' fill-rule='evenodd' d='M12.207 5.89336c-.3905-.39053-1.0237-.39053-1.4142 0L5.3933 11.2929c-.39053.3905-.39053 1.0237 0 1.4142l5.3995 5.3995c.3905.3905 1.0237.3905 1.4142 0 .3905-.3905.3905-1.0237 0-1.4142L8.51462 13h9.38478c.5523 0 1-.4477 1-1s-.4477-1-1-1H8.51462L12.207 7.30757c.3905-.39052.3905-1.02369 0-1.41421Z' clip-rule='evenodd' style='fill: %23293034;' /%3E%3C/svg%3E%0A");
            }
          }

          &:focus {
            &:not(:focus-visible) {
              box-shadow: none;
            }

            &:focus-visible {
              @include focus();
            }
          }

          &::before {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24'%3E%3Cpath fill='currentColor' fill-rule='evenodd' d='M12.207 5.89336c-.3905-.39053-1.0237-.39053-1.4142 0L5.3933 11.2929c-.39053.3905-.39053 1.0237 0 1.4142l5.3995 5.3995c.3905.3905 1.0237.3905 1.4142 0 .3905-.3905.3905-1.0237 0-1.4142L8.51462 13h9.38478c.5523 0 1-.4477 1-1s-.4477-1-1-1H8.51462L12.207 7.30757c.3905-.39052.3905-1.02369 0-1.41421Z' clip-rule='evenodd' style='fill: %230060ff;' /%3E%3C/svg%3E%0A");
          }
        }

        li.active-toc {
          .table-of-contents__second-level-link {
            &::before {
              content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='currentColor' fill-rule='evenodd' d='M12.207 5.89336c-.3905-.39053-1.0237-.39053-1.4142 0L5.3933 11.2929c-.39053.3905-.39053 1.0237 0 1.4142l5.3995 5.3995c.3905.3905 1.0237.3905 1.4142 0 .3905-.3905.3905-1.0237 0-1.4142L8.51462 13h9.38478c.5523 0 1-.4477 1-1s-.4477-1-1-1H8.51462L12.207 7.30757c.3905-.39052.3905-1.02369 0-1.41421Z' clip-rule='evenodd' style='fill: %230C3243;' /%3E%3C/svg%3E%0A");
            }
          }
        }
      }
    }
  }

  &--contact {
    padding: 24px;
  }

  &__wrapper-100 {
    width: 100%;
  }

  &__contact-title {
    font-weight: 600;
    color: $gray-900;
    margin-bottom: 16px;
  }

  &__contact {
    display: flex;
    margin-bottom: 24px;

    span {
      font-weight: 600;
      margin-inline-start: 12px;
    }

    .icon {
      color: $business-500;
    }
  }

  ul.table-of-contents__social-links-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 24px !important;
    margin-top: 16px;
    margin-bottom: 0;
    position: relative;

    @include media-breakpoint-up(lg) {
      justify-content: center;
    }

    &::before {
      content: "";
      display: block;
      height: 1px;
      width: 100%;
      position: absolute;
      top: 0;
      inset-inline-start: 0;
      background-color: $gray-200;
    }

    li {
      &.table-of-contents__social-list-item {
        margin-bottom: 0 !important;
        margin-inline-end: 24px;
        padding: 0 !important;

        .table-of-contents__social-link {
          color: $gray-500;
        }

        &:last-child {
          margin-inline-end: 0;
        }
      }
    }
  }
  .accordion {
    padding: 0;
    display: flex;
    flex-direction: column;
  }
  .accordion-item {
    width: 100%;
    border: none;
    border-bottom: 1px solid $gray-200;

    &:last-child {
      border-bottom: none;
    }

    .accordion-header {
      width: 100%;
      border: none;
      box-shadow: none;

      button.accordion-button {
        padding: 24px 0;
        border: none;
        background-color: $gray-50;
        outline: none;
        box-shadow: none;
        color: $gray-900;
        font-weight: 600;
        font-size: 20px;

        &::after {
          background-image: var(--bs-accordion-btn-icon);
        }
      }
    }

    .accordion-collapse {
      border: none;
      background-color: $gray-50;

      .accordion-body {
        padding: 0 0 8px;
        border: none;

        ul {
          margin-bottom: 0;

          li {
            margin-bottom: 24px !important;

            a {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
