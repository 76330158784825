@import "../../../../../styles/custom";

.document-section {
  margin-bottom: 40px;

  .accordion {
    background-color: $gray-0;
    &.open {
      border: none;
      box-shadow: none;
    }
  }

  &__description {
    margin-bottom: 20px;
    @include media-breakpoint-down(lg) {
      margin-bottom: 16px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__list-item {
    display: flex;
    align-items: center;
    gap: 12px;
    align-self: stretch; 
    padding: 12px 0.75rem;
    border-radius: 4px;
    background-color: $gray-0;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: $gray-50;
    }
  }

  &__document-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    color: $gray-900;
    flex: 1;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-left: auto;
  }

  &__document-size {
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    color: $gray-900;
  }
}
