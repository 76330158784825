@import "../../../styles/custom";

.result-row {
  display: flex;
  gap: 8px;
  border-bottom: 1px solid $gray-100;
  padding: 16px;
  max-width: 100%;

  &:hover {
    background-color: $business-100;
  }

  @include media-breakpoint-up(lg) {
    gap: 24px;
    padding: 16px 24px;
  }

  strong {
    font-weight: 600;
    background-color: #fffae2;
  }

  .result-icon.icon {
    width: 24px;
    min-width: 24px;
    color: $gray-600;
    position: relative;
    top: 2px;
  }

  &__result-content {
    width: calc(100% - 48px);
  }

  &__result-content-title {
    color: $business-500;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 4px;

    @include media-breakpoint-up(lg) {
      font-size: 20px;
    }
  }

  &__result-content-excerpt {
    color: $gray-700;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 12px;
    white-space: nowrap;
    width: 100%;
    overflow-x: hidden;
    text-overflow: ellipsis;

    @include media-breakpoint-up(lg) {
      font-size: 16px;
    }

    * {
      white-space: nowrap;
      width: 100%;
      overflow-x: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
    }
  }

  &__result-footer {
    display: flex;
    gap: 12px;

    div {
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
      color: $gray-800;
    }
  }

  &__footer-divider {
    color: $gray-200 !important;
  }

  &__result-footer-date {
    font-size: 16px;
    font-weight: 400;
    color: $gray-600;
    margin-top: 12px;
  }

  &__material-tag {
    display: flex;
    align-items: center;
    gap: 8px;

    &--persona-material {
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }

  &__tag-icon {
    color: $business-500;
  }

  &__definitions-tag {
    background: $gray-100;
    padding: 2px 6px;
    border-radius: 4px;
  }

  &--calculators {
    .result-row__result-footer {
      display: none;
    }

    .result-row__result-content-excerpt {
      margin-bottom: 0;
    }
  }

  &__materials-content {
    font-weight: inherit;

    &--persona {
      white-space: nowrap;
      width: 100%;
      overflow-x: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
    }
  }

  &__service-content {
    display: flex;
    gap: 8px;

    &--with-child-materials {
      display: flex;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }
}
