@import "../../styles/custom";

.end-of-service-reward {
  background-color: $gray-50;
  padding-bottom: 48px;
  position: relative;

  &__empty-error-for-align {
    margin-bottom: 29px;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 120px;
  }

  &__content {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      flex-wrap: nowrap;
      width: auto;
    }
  }

  &__text-content {
    width: calc(100% - 32px);

    @include media-breakpoint-up(lg) {
      max-width: 50%;
      width: 100%;
    }
  }

  &__calc-content,
  &__calc-result {
    background: white;
    border: 1px solid $gray-200;
    border-right-width: 0;
    border-left-width: 0;
    width: 100%;
    min-width: 100vw;
    padding: 32px 16px;
    position: relative;
    inset-inline-start: -16px;

    &__result-title {
      display: inline-block;
      font-size: 24px;
      font-weight: 600;
      color: $gray-900;
      margin-bottom: 25px;
    }

    &--required {
      font-size: 14px;
      margin: 5px 0;
      color: $danger-500;
      font-weight: 500;
    }

    &__radio-wrapper {
      cursor: pointer;

      label {
        height: 100%;
        width: 100%;
        padding: 15px 35px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      max-width: 50%;
      border-radius: 8px;
      border-right-width: 1px;
      border-left-width: 1px;
      padding: 40px 32px;
      min-width: auto;
      inset-inline-start: 0;
    }

    &__date-container {
      display: flex;
      justify-content: space-between;
      gap: 8px;
    }

    &__date-control {
      width: 50%;
    }

    &__form {
      fieldset[data-component="RadioTileGroup"] {
        margin-bottom: 24px;
      }

      label[data-component="RadioTile"] {
        width: 100%;
      }

      div[data-component="Select"] {
        div[data-tippy-root] {
          position: absolute !important;
          top: 60px !important;
          left: 0 !important;
          transform: unset !important;
        }
      }
    }
  }

  &__return-btn {
    background-color: white;
    border: none;
    display: flex;
    align-items: center;
    color: $business-500;
    margin-bottom: 32px;
    &__mobile{
      margin-top: 8px;
    }
    &--top {
      margin-inline-start: -15px;
      margin-bottom: 8px;
      display: none;

      @include media-breakpoint-up(lg) {
        display: flex;
      }
    }

    @at-root body.arabic {
      .certificate-validation-tool__return-btn--top {
        .icon {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__result-list {
    list-style: none;
    margin: 0;
    padding: 0;
    border-top: 1px solid $gray-200;
  }

  &__result-item {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    align-items: center;
    padding: 24px 0;
    border-bottom: 1px solid $gray-200;

    &:last-of-type {
      border-bottom: none;

      .end-of-service-reward__result-item-value {
        font-size: 32px;
      }
    }

    .certificate-validation-tool__result-item-label,
    .certificate-validation-tool__result-item-value {
      margin: 0;
      font-size: 16px;
    }

    &:last-child {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__result-item-label {
    font-weight: 400;
    margin: auto 0;
  }

  &__result-item-value {
    font-weight: 600;
    text-align: end;
    margin: auto 0;
    max-width: 60%;

    span {
      font-weight: 600;
    }

    &--reward {
      max-width: 100%;
      white-space: nowrap;
    }
  }

  &__title {
    font-size: 48px;
    font-weight: 700;
    letter-spacing: -0.5px;
    margin: 0;
    max-width: 457px;
  }

  &__description {
    font-size: 16px;
    font-weight: 400;
    margin: 16px 0 0;
    max-width: 457px;
  }

  &__description :first-child {
    margin-bottom: 36px;
  }

  &__validation-result {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
  }

  &__cert-status {
    text-transform: uppercase;
    font-weight: 16px;
    margin: 0;
    padding: 0 4px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    color: white;

    &--expired {
      background-color: $danger-500;
    }

    &--active {
      background-color: $success-900;
    }
  }

  &__error-msg {
    color: $nitaqat-red;
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    inset-inline-start: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.5;
    z-index: 10;
  }

  &__salary-input {
    div[data-component="NumericField"] {
      p {
        white-space: nowrap;
      }
    }

    &--rtl {
      input {
        direction: rtl !important;
      }
    }

    &--ltr {
      input {
        direction: ltr !important;
      }
    }
  }
}
