@import "../../styles/custom";

.numbers-block {
  background-color: $business-800;
  padding: 56px 0 64px;
  color: $white;
  background-repeat: no-repeat;
  background-position: bottom;
  max-width: 100vw;
  overflow-x: hidden;

  @include media-breakpoint-up(md) {
    padding: 40px 0 64px;
  }

  @include media-breakpoint-up(lg) {
    background-image: url("../../../public/img/background-lines.svg");
    padding: 80px 0 120px;
    background-size: 100%;
  }

  & &__media-wrapper {
    width: 100%;

    @include media-breakpoint-up(lg) {
      height: 598px;
    }
  }

  & &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &--desktop {
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    &--mobile {
      display: block;
      margin-bottom: 32px;

      @include media-breakpoint-up(md) {
        padding-inline-end: 18px;
        margin-bottom: 0;
      }

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }

  & &__title {
    font-size: 32px;
    font-weight: 600;
    line-height: 130%;
    margin-bottom: 16px;

    @include media-breakpoint-up(lg) {
      font-size: 48px;
      margin-bottom: 32px;
      letter-spacing: -0.5px;
    }
  }

  & &__tabs-column {
    @include media-breakpoint-up(lg) {
      padding-inline-start: 48px;
    }
  }

  & &__tabs-list {
    border-bottom: none;
    flex-wrap: nowrap;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 3px;

    &::-webkit-scrollbar {
      display: none;
    }

    @include media-breakpoint-down(md) {
      pointer-events: all;
      padding: 3px;
    }

    .nav-item {
      text-decoration: none;

      .nav-link {
        padding: 16px 10px;
        color: $gray-300;
        margin-inline-end: 32px;
        font-weight: 600;
        border: none;
        white-space: nowrap;
        position: relative;

        &::after {
          content: "";
          display: block;
          width: calc(100% - 20px);
          height: 3px;
          position: absolute;
          bottom: 0;
          inset-inline-start: 10px;
          background-color: transparent;
        }

        &:hover {
          border: none;
          color: $white;
          font-weight: 600;
        }

        &.active {
          background-color: transparent;
          color: $white;
          font-weight: 600;
          position: relative;

          &::after {
            background-color: $white;
          }
        }
      }

      .nav-link:focus {
        &:not(:focus-visible) {
          box-shadow: none;
        }

        &:focus-visible {
          @include focus();
        }

        &::after {
          background-color: transparent;
        }
      }
    }
  }

  & &__tab-content {
    padding-top: 24px;

    @include media-breakpoint-up(md) {
      padding-top: 40px;
    }

    @include media-breakpoint-up(lg) {
      padding-top: 56px;
    }
  }

  & &__tab-content-row {
    display: flex;
    flex-wrap: wrap;

    @include media-breakpoint-up(lg) {
      display: block;
    }

    .numbers-block__tab-content-row--column {
      height: 430px;
      width: 100%;

      @include media-breakpoint-up(md) {
        width: 346px;
      }

      @include media-breakpoint-up(lg) {
        height: 0;
        width: 100%;
      }
    }

    .tab-content {
      width: 100%;

      @include media-breakpoint-up(md) {
        width: 385px;
        padding-inline-start: 48px;
      }

      @include media-breakpoint-up(lg) {
        padding-inline-start: 0;
        width: 100%;
      }
    }
  }

  & &__tab-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 140%;
    margin-bottom: 32px;

    @include media-breakpoint-up(lg) {
      font-size: 32px;
      margin-bottom: 48px;
      line-height: 130%;
    }
  }

  & &__numbers-wrapper {
    --bs-gutter-x: 24px;

    @include media-breakpoint-up(lg) {
      --bs-gutter-x: 40px;
    }
  }

  & &__numbers-container {
    margin-bottom: 36px;

    &:nth-last-child(-n + 2) {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 48px;
    }

    &--small {
      .numbers-block__number-wrapper,
      .numbers-block__number {
        font-size: 32px;
        line-height: 130%;
      }

      .numbers-block__number-label {
        font-size: 12px;
      }
    }
  }

  & &__number-wrapper {
    font-size: 32px;
    font-weight: 700;
    line-height: 130%;

    @include media-breakpoint-up(lg) {
      font-size: 40px;
      line-height: 150%;
    }
  }

  & &__number {
    font-size: 32px;
    font-weight: 700;
    line-height: 130%;
    margin-bottom: 24px;
    display: inline-block;

    @include media-breakpoint-up(lg) {
      font-size: 40px;
      line-height: 150%;
    }
  }

  & &__number-label {
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;

    @include media-breakpoint-up(lg) {
      font-size: 14px;
    }
  }

  & &__next-button,
  & &__prev-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 56px;
    height: 56px;
    border: 0;
    padding: 0;
    background-color: $business-800;
    color: $white;

    &:focus {
      &:not(:focus-visible) {
        box-shadow: none;
      }

      &:focus-visible {
        @include focus();
      }
    }

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  & &__next-button {
    inset-inline-end: 0;
  }

  & &__prev-button {
    inset-inline-start: 0;
  }

  & &__nav-wrapper {
    position: relative;
  }
}
