@import "../../../styles/custom";

.footer {
  border-top: 1px solid $gray-200;
  padding: 32px 0 0;
  background-color: $white;

  @include media-breakpoint-up(md) {
    padding: 40px 0 0;
  }

  @include media-breakpoint-up(xl) {
    padding: 64px 0 0;
  }

  & &__logos-row {
    padding-bottom: 32px;
    margin-block-end: 32px;
    position: relative;
    justify-content: center;

    &::after {
      position: absolute;
      content: "";
      bottom: 0;
      inset-inline-start: 4px;
      height: 1px;
      width: calc(100% - 8px);
      background-color: $gray-200;
    }

    .kc-link {
      outline: none;

      &:focus {
        &:focus-visible {
          img {
            @include focus();
          }
        }
      }
    }

    @include media-breakpoint-up(md) {
      margin-block-end: 48px;
      justify-content: unset;

      &::after {
        display: none;
      }
    }

    @include media-breakpoint-up(xl) {
      margin-block-end: 56px;
    }
  }

  & &__logos-col {
    &--left {
      div[data-component="Divider"] {
        display: none;
      }
      @include media-breakpoint-down(sm) {
        div[data-component="Divider"] {
          display: block;
          margin-top: 24px;
        }
      }
    }
    &--right {
      display: flex;
      justify-content: flex-end;
      gap: 48px;

      @include media-breakpoint-down(md) {
        gap: 24px;
        justify-content: center;
      }
      @include media-breakpoint-down(sm) {
        gap: 24px;
        margin-top: 24px;
      }
      @include media-breakpoint-down(xs) {
        gap: 8px;
      }
    }

    &--right-dga {
      display: flex;
      justify-content: flex-end;
      padding: 0;
    }
  }

  & &__logo-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    height: auto;

    a {
      img {
        max-width: none;
      }
    }
    @include media-breakpoint-up(lg) {
      height: 64px;
      align-items: center;
    }

    &--dga-logo {
      a {
        width: 100%;

        @include media-breakpoint-up(md) {
          margin-top: 0;
        }

        svg {
          float: inline-end;
        }
      }

      @include media-breakpoint-up(lg) {
        width: auto;
      }

      @include media-breakpoint-up(md) {
        width: max-content;
      }
    }

    a {
      @include media-breakpoint-up(lg) {
        width: 100%;
      }
    }
  }

  & &__footer-main-logo {
    width: 64px;
    height: 64px;

    @include media-breakpoint-up(md) {
      width: 64px;
      height: 56px;
    }
  }

  &__takamol-logo-container {
    padding: 7px 7px 6px 5px;
  }
  & &__takamol-logo {
    height: 35px;
    width: 68px;
    float: right;

    @include media-breakpoint-up(md) {
      height: 48px;
      width: 93px;
    }
  }

  & &__arabic-takamol-logo {
    height: 40px;
    width: 110px;
    float: right;

    @include media-breakpoint-up(md) {
      width: 132px;
      height: 48px;
    }
  }

  & &__dga-takamol-logo {
    height: 117px;
    width: 100%;

    @include media-breakpoint-up(md) {
      height: 64px;
      width: 190px;
    }
  }

  & &__sections-row {
    --bs-gutter-y: 2rem;
    margin-block-end: 0;
    flex-direction: column;
    margin-block-end: 32px;

    @include media-breakpoint-up(md) {
      max-height: 650px;
      margin-block-end: 48px;
    }

    @include media-breakpoint-up(xl) {
      max-height: 450px;
      margin-block-end: 80px;
    }
  }

  & &__sections-col {
    margin-block-end: 0;
  }

  & &__section-title {
    margin-block-end: 16px;
    color: $gray-900;
    font-size: 20px;
    font-weight: 600;
    line-height: 140%;

    a {
      text-decoration: none;
      color: $gray-900;
      font-weight: 600;
    }
  }

  & &__section-links-list {
    list-style: none;
    padding: 0;
    margin-block-end: 0;
  }

  & &__section-list-item {
    margin-block-end: 16px;

    &:last-child {
      margin-block-end: 0;
    }
  }

  & &__section-link {
    line-height: 150%;
    font-size: 16px;

    @include media-breakpoint-up(md) {
      font-size: 14px;
    }

    @include media-breakpoint-up(xl) {
      font-size: 16px;
    }

    .kc-link__label {
      font-weight: 400;
    }
  }

  & &__cta-bar {
    padding: 32px 24px;
    background-color: $business-100;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-block-end: 32px;

    @include media-breakpoint-up(md) {
      padding: 32px 24px;
      text-align: initial;
      justify-content: space-between;
      margin-block-end: 48px;
    }

    @include media-breakpoint-up(xl) {
      padding: 44px 24px;
    }

    p {
      margin: 0 0 24px;

      @include media-breakpoint-up(md) {
        margin: 0;
      }
    }
  }

  &__control-bar {
    margin-bottom: 48px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 24px;

    @include media-breakpoint-up(md) {
      gap: 32px;
    }

    @include media-breakpoint-up(lg) {
      flex-wrap: nowrap;
      justify-content: flex-end;
    }
  }

  &__control-bar-buttonbox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px;
    max-width: 427px;

    @include media-breakpoint-up(md) {
      gap: 12px;
    }
  }

  & &__control-bar-link {
    display: flex;
    height: 40px;
    justify-content: center;
    align-items: center;
    background: $white;
    padding: 4px 8px;
    border: 1px solid $gray-300;
    width: calc(33% - 8px);
    border-radius: 4px;

    img {
      height: 100%;
      width: 100%;
    }

    &--apple {
      @include media-breakpoint-up(md) {
        width: 128px;
      }
    }

    &--google {
      @include media-breakpoint-up(md) {
        width: 140px;
      }
    }

    &--huawei {
      @include media-breakpoint-up(md) {
        width: 135px;
      }
    }
  }

  & &__cta-bar-link {
    background-color: $business-500;
    border-radius: 4px;
    padding: 12px 24px;
    text-decoration: none;
    color: $white;
    font-weight: 500;
    width: 100%;

    &:hover {
      background-color: $business-600;
      color: $white;
    }

    &:active {
      background-color: $business-700;
      color: $white;
    }

    &:focus {
      &:not(:focus-visible) {
        box-shadow: none;
      }

      &:focus-visible {
        @include focus();
      }
    }

    @include media-breakpoint-up(md) {
      width: auto;
    }
  }

  & &__social-footer {
    width: 100%;

    @include media-breakpoint-up(md) {
      border-top: 1px solid $gray-200;
    }
  }

  & &__social-footer-row {
    padding: 0;
    align-items: center;

    @include media-breakpoint-up(md) {
      padding: 48px 0 64px;
    }

    @include media-breakpoint-up(xl) {
      padding: 48px 0 80px;
      flex-wrap: nowrap;
    }
  }

  & &__social-footer-col {
    &--social {
      order: 2;
      padding-top: 16px;
      padding-bottom: 16px;
      position: relative;

      @include media-breakpoint-up(md) {
        order: 1;
        padding-top: 0;
        padding-bottom: 0;

        &::after {
          display: none;
        }
      }

      &::after {
        position: absolute;
        content: "";
        width: calc(100% - 8px);
        height: 1px;
        background-color: $gray-200;
        bottom: 0;
        inset-inline-start: 4px;
      }

      .footer__social-list-item {
        &:not(:last-child) {
          margin-inline-end: 24px;

          @include media-breakpoint-up(md) {
            margin-inline-end: 40px;
          }
        }
      }

      .footer__social-link {
        color: $gray-600;
      }
    }

    &--secondary {
      order: 1;
      position: relative;

      @include media-breakpoint-up(md) {
        order: 2;

        &::after {
          display: none;
        }
      }

      &::after {
        position: absolute;
        content: "";
        width: calc(100% - 8px);
        height: 1px;
        background-color: $gray-200;
        bottom: 0;
        inset-inline-start: 4px;
      }

      .footer__social-links-list {
        padding-bottom: 16px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        @include media-breakpoint-up(md) {
          flex-direction: row;
          padding-top: 64px;
          padding-bottom: 64px;
          justify-content: center;
          align-items: center;
        }

        @include media-breakpoint-up(xl) {
          padding-top: 0;
          padding-bottom: 0;
          justify-content: start;
        }
      }

      .footer__social-list-item {
        margin-inline-end: 0;
        margin-block-end: 16px;

        @include media-breakpoint-up(md) {
          margin-block-end: 0;
        }

        &:not(:last-child) {
          @include media-breakpoint-up(md) {
            margin-inline-end: 24px;
          }
        }
      }

      .footer__social-link {
        color: $gray-900;

        @include media-breakpoint-up(md) {
          font-size: 14px;
        }
      }
    }
  }

  & &__social-links-list {
    list-style: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;

    @include media-breakpoint-only(md) {
      justify-content: center;
    }
  }

  & &__social-link {
    color: $gray-500;
    text-decoration: none;
    font-size: 16px;

    @include media-breakpoint-up(md) {
      font-size: 12px;
    }

    &:hover,
    &:active {
      color: $business-500;
    }

    &:not(.kc-link--text-link) {
      display: inline-block;
      height: 100%;

      &:focus {
        &:not(:focus-visible) {
          box-shadow: none;
        }

        &:focus-visible {
          @include focus();
        }
      }
    }

    &.kc-link--text-link {
      &:focus {
        &:focus-visible {
          color: black;
        }
      }
    }
  }

  & &__copyright {
    align-self: flex-start;
    text-align: left;
    color: $gray-600;
    font-size: 12px;
    order: 3;
    padding-top: 16px;
    padding-bottom: 16px;

    @include media-breakpoint-up(md) {
      text-align: center;
      align-self: center;
      padding-top: 0;
      padding-bottom: 0;
    }

    @include media-breakpoint-up(xl) {
      text-align: right;
      align-self: flex-end;
    }
  }
}
