@import "../../../styles/custom";

.breadcrumbs-layout {
  position: fixed;
  top: 500;
  left: 0;
  right: 0;
  z-index: 100;
  transition: transform 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-100%);

  // Light theme (default)
  background-color: $white;
  border-top: 1px solid $gray-200;
  border-bottom: 1px solid $gray-200;

  @media screen and (max-width: 1278px) {
    background-color: $white !important;
    border-top: 1px solid $gray-200 !important;
    border-bottom: 1px solid $gray-200 !important;

    .breadcrumbs-kc {
      &__link {
        color: $business-500 !important;
        
        a {
          color: $business-500 !important;
          
          &:hover {
            color: $business-600 !important;
          }
        }
      }

      .icon {
        color: $business-500 !important;
      }
    }
  }

  // Dark theme
  &--dark {
    background-color: $business-800;
    border-top: 1px solid $gray-200;
    border-bottom: 1px solid $gray-200;

    .breadcrumbs-kc {
      &__link {
        color: $gray-200;
        
        a {
          color: $gray-200;
          
          &:hover {
            color: $gray-200;
          }
        }
      }

      .icon {
        color: $gray-200;
      }
    }
  }

  // Light theme specific styles
  &--light {
    background-color: $white;
    border-top: 1px solid $gray-200;
    border-bottom: 1px solid $gray-200;

    span{
        color: $gray-600;
    }

    .breadcrumbs-kc {
      &__link {
        color: $business-500;
        
        a {
          color: $business-500;
          
          &:hover {
            color: $business-600;
          }
        }
      }

      .icon {
        color: $business-500;
      }
    }
  }

  &--visible {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }

  &__container {
    padding: 12px 0;
    margin: 0 auto;
    width: 100%;
    max-width: 1127px;

    @include media-breakpoint-down(xl) {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
} 