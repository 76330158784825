@import "../../../styles/custom";

.user-profile-pane {
  position: absolute;
  inset-block-start: -1px;
  inset-inline-start: 100%;
  height: 100%;
  width: 0;
  background-color: #fff;
  z-index: 201;
  transition: all 0.2s ease-in;
  overflow-x: hidden;
  overflow-y: auto;
  border-top: 1px solid #e6e6e7;

  &--open {
    width: 100%;
    inset-inline-start: 0;
  }

  .container {
    padding: 16px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  &__back-btn {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    color: $business-500;
    cursor: pointer;

    .icon {
      width: 24px;
      height: 24px;
    }
  }

  &__section-title {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: $gray-900;
    margin-bottom: 24px;
  }

  &__name {
    font-size: 20px;
    font-weight: 600;
    color: $gray-900;
    line-height: 140%;
    padding-bottom: 16px;
    border-bottom: 1px solid #e6e6e7;
  }

  &__links {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
  }

  &__link {
    text-decoration: none;
    color: $gray-900;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    padding: 26px 12px;
    border: none;
    background: none;
    text-align: left;
    cursor: pointer;

    &--blue {
      color: $business-500;
      text-decoration: underline;
    }

    &--divider {
      border-bottom: 1px solid $gray-200;
      border-top: 1px solid $gray-200;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}

[dir="rtl"] {
  .user-profile-pane {
    inset-inline-start: -100%;
    inset-inline-end: auto;

    &--open {
      inset-inline-start: 0;
    }

    &__back-btn {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}
