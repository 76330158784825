@import "../../../../../styles/custom";
.video-section {
    .accordion {
      background-color: $gray-0;
      &.open { 
        border: none;
        box-shadow: none;
      }
    }

  &__description {
    margin-bottom: 20px;
    @include media-breakpoint-down(lg) {
        margin-bottom: 16px;
    }
  }

  &__player {
    margin-bottom: 32px;
    @include media-breakpoint-down(lg) {
        margin-bottom: 16px;
    }
  }

  &__playlist {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 40px;
    @include media-breakpoint-down(lg) {
        margin-bottom: 32px;
    }
  }

  &__playlist-item {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.75rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &.active {
      background-color: $business-100;
      .video-section__video-title {
        color: $business-600;
      }
      .video-section__video-duration {
        color: $business-600;
      }
    }
  }

  &__play-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: var(--primary);
    border: none;
    cursor: pointer;
    padding: 0;

    &:hover {
      background-color: var(--primary-dark);
    }
  }

  &__icon {
    width: 16px;
    height: 16px;
    color: white;
  }

  &__video-title {
    flex: 1;
    font-weight: 500;
  }

  &__video-duration {
    font-size: 0.875rem;
  }
} 