@import "../../../styles/custom";

.search-results {
  padding-bottom: 64px;
  position: relative;

  @include media-breakpoint-up(lg) {
    padding-bottom: 120px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 48px;

    &__border-bottom {
      padding-bottom: 8px;
      border-bottom: 1px solid $gray-100;
      @include media-breakpoint-up(lg) {
        border-bottom: 1px solid $gray-800;
        padding-bottom: 32px;
      }
    }
  }

  &__result-description {
    font-size: 16px;
    max-width: 456px;

    span {
      font-weight: 600;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 52px;
      font-size: 24px;
    }

    &--suggestion {
      margin-top: 4px;
      font-size: 16px;
    }
  }

  &__sort-dropdown {
    width: 240px;
    padding: 12px 16px;
  }

  &__sort-dropdown-wrapper {
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  @at-root body.arabic {
    .search-results__sort-dropdown {
      background-position: left 0.75rem center;
    }
  }

  &__results-block {
    padding: 0;
    padding-top: 16px;

    @include media-breakpoint-up(lg) {
      padding-top: 36px;
    }
    &__popular {
      padding-top: 24px;
      @include media-breakpoint-up(lg) {
        padding-top: 48px;
      }
    }
  }

  &__results-block-title {
    padding: 0 16px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 8px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 24px;
      padding: 0 0 24px;
      font-size: 24px;
      text-transform: capitalize;
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__results-block-amount {
    display: none;

    @include media-breakpoint-up(lg) {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      display: block;
    }

    &--mobile {
      display: inline;
      font-weight: 700;

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }

  &__result-link {
    &:focus-visible {
      .result-row {
        @include focus();
      }
    }
  }
  &__popular-on-qiwa {
    &__title {
      margin-top: 32px;
      color: $gray-900;
      font-size: 24px;
      font-weight: 600;
      line-height: 140%;
      @include media-breakpoint-down(lg) {
        font-size: 16px;
        padding-bottom: 16px;
        font-weight: 700;
        line-height: 150%;
        border-bottom: 1px solid $gray-100;
        margin-top: 24px;
      }
    }
  }

  &__no-results {
    margin-top: 16px;
    padding-bottom: 24px;
    border-bottom: 1px solid $gray-800;
    @include media-breakpoint-up(lg) {
      border-bottom: 2px solid $gray-800;
      padding-bottom: 32px;
      margin-top: 24px;
    }

    p {
      font-size: 16px;
      margin-bottom: 12px;
    }

    ul {
      margin: 0;
      padding-inline-start: 20px;
      list-style-type: disc;

      li {
        font-size: 16px;
        margin-bottom: 8px;
      }
      li::marker {
        color: $business-600;
      }
    }
  }

  &__show-more-services-block {
    margin-top: 12px;

    @include media-breakpoint-up(lg) {
      margin-top: 24px;
    }
  }

  &__definition-block {
    margin-top: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid $gray-800;

    @include media-breakpoint-down(lg) {
      margin-top: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid $gray-100;
    }

    &__container {
      @include media-breakpoint-down(lg) {
        background-color: $business-100;
        padding: 16px;
      }
    }

    &__title-section {
      margin-bottom: 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    &__title {
      display: flex;
      padding: 2px 6px;
      align-items: flex-start;
      border-radius: 4px;
      background: $gray-200;
      font-size: 12px;
      font-weight: 700;
      line-height: 150%;
      letter-spacing: 0.4px;
      text-transform: uppercase;
      @include media-breakpoint-down(lg) {
        color: $gray-800;
        background-color: transparent;
        padding: 0;
      }
    }
    &__content {
      &__more-button {
        color: $business-500;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; 
        text-decoration-line: underline;
      }
      &__expanded {
        p {
          -webkit-line-clamp: unset !important;
        }
      }
      p {
        align-self: stretch;
        overflow: hidden;
        color: $gray-900;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;

        strong {
          font-size: 16px;
          font-weight: 400;
          line-height: 150%;
        }
      }
    }
  }
}
