@import "../../../../../styles/custom";

.permission-main {
  background-color: $gray-50 !important;
  background: url("../../../../../../public/img/big-grey-logo.png") no-repeat
    right 9px;
  background-size: contain;
  padding-left: 156px;
  direction: ltr;
  a {
    color: $business-500;
  }

  @include media-breakpoint-down(md) {
    background: none;
    padding-left: 16px;
    padding-right: 16px;
  }
  &.permission-main--rtl {
    background: url("../../../../../../public/img/big-grey-logo.png") no-repeat
      left 9px;
    background-size: contain;
    direction: rtl;
    padding-right: 156px;
    padding-left: 0px;
    @include media-breakpoint-down(md) {
      background: none;
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .permission-content {
    padding-top: 40px;
    padding-bottom: 80px;

    .permission-header {
      font-size: 48px;
      font-weight: 600;
      color: $gray-900;
      line-height: 130%;
      @include media-breakpoint-down(md) {
        font-size: 36px;
      }
    }
    .permission-error-code {
      font-size: 20px;
      font-weight: 400;
      color: $gray-700;
      line-height: 140%;
      @include media-breakpoint-down(md) {
        font-size: 16px;
      }
    }
    .permission-message {
      font-size: 20px;
      font-weight: 400;
      color: $gray-900;
      line-height: 140%;
      @include media-breakpoint-down(md) {
        font-size: 16px;
      }
    }
    .permission-try-ways {
      font-size: 16px;
      font-weight: 400;
      color: $gray-900;
      line-height: 150%;
    }
  }
}

.permission-search {
  a {
    color: $business-500;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 16px;
    text-decoration: none;
    &:hover {
      color: $business-600;
    }
  }
  background-color: $gray-0 !important;
  padding-top: 56px;
  padding-bottom: 120px;
  padding-left: 156px;
  padding-right: 156px;
  direction: ltr;

  &.permission-search--rtl {
    background-color: $gray-0 !important;
    direction: rtl;
    @include media-breakpoint-down(md) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .permission-search-component {
    padding-left: 0px;
    margin-bottom: 40px;
  }

  @include media-breakpoint-down(md) {
    background: none;
    padding-left: 16px;
    padding-right: 16px;
  }
  .permission-search-title {
    font-size: 16px;
    font-weight: 400;
    color: $gray-900;
    line-height: 150%;
    @include media-breakpoint-down(md) {
      font-size: 20px;
    }
  }
  .permission-search-header {
    margin-bottom: 24px;
    font-size: 20px;
    font-weight: 600;
    color: $gray-900;
    line-height: 140%;
  }
}

.permission-search .hero-search-input__input-wrapper {
  border-radius: 4px;
  border: 1px solid $gray-300;
  padding: 16px 12px 16px 12px;
  @include media-breakpoint-down(lg) {
    border: none;
    padding: 0px;
  }
}
.permission-search .hero-search-input__wrapper {
  padding: 16px 12px 16px 12px;
  margin-right: 4px;
}
.permission-search--rtl .hero-search-input__wrapper {
  padding: 16px 12px 16px 12px;
  margin-right: 0px;
  margin-left: 4px;
}

.permission-search--rtl .hero-search-input__input-wrapper {
  margin-left: 4px;
  margin-right: 0px;
}
.permission-search--rtl .hero-search-input__search-btn {
  padding-right: 36px;
  padding-left: 36px;
}
